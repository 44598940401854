
import { RATE_RISE_NUDGEBAR_ALLOWED_PATHS } from '~/constants/components.strings'

export const useRateRiseNudgebarStore = defineStore('rate-rise-nudge-bar', {
  state: () => ({
  }),

  getters: {
    dualRateConfig () {
      const { ratesMessageDates } = useEnv()
      return ratesMessageDates
    },

    showRateRiseNudgeBar () {
      const route = useRoute()
      const _path = route.path.substring(0, route.path.length - 1)

      return (RATE_RISE_NUDGEBAR_ALLOWED_PATHS.includes(_path))
    }
  }
})
